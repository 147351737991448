<template>
    <div class="" :class="{homeTourn : home == true}" >
      <template v-if="home != true">
        <div v-for="(tourn, index) in nextTournament" :key="index">
            <b-row class="image">
                <b-col v-if="tourn.course_images == 'Y'" class="imagewidthNav" v-bind:style="{ 'background-image': 'url(' + 'https://ocs-asia.sgp1.cdn.digitaloceanspaces.com/apga/courses/' + season + '/' + tourn.course_code +'/' + 'File_1.jpg' + ')' }">
                </b-col>
                <b-col v-else class="imagewidthNav" v-bind:style="{ 'background-image': 'url(' + 'https://assets.asiantour.com/asian-tour/2022/06/sliderdummycourse.png)' }">
                </b-col>
            </b-row>  
            <b-row class="nextbtnColorNav" :class="{bkHome : home == true}">
                <b-col :class="{homeBlock : home == true}">
                    <div :class="{homeBlockInner : home == true}">
                        <template v-if="home == true">
                            <img class="sponsorLogoHome" :src="tourn.sponsor_logo">
                        </template>
                        <p class="fullNameNav">{{tourn.short_name}}</p>
                        <p class="dicDatesNav">{{tourn.dic_dates}}</p>
                    </div>
                </b-col>
            </b-row> 
            <template v-if="home == true">
              <!-- <b-row class="NextBottomBlie" v-if="Array.isArray(reports)">
                  <b-col>
                      <div class="FullListingBtn">
                          <b-link @click="toggle" :to="'/tournament/' + tourn.code + '/' + season" class="btn btn-secondary" target="_self">View Tournament</b-link>
                      </div>
                  </b-col>
              </b-row> -->
            </template>
            <template v-else>
              <b-row class="NextBottomBlie">
                  <b-col>
                      <div class="FullListingBtnNav">
                          <b-link @click="toggle" :to="'/tournament/' + tourn.code + '/' + season" class="btn btn-secondary" target="_self">View Tournament</b-link>
                      </div>
                  </b-col>
              </b-row>     
            </template>   
        </div>
      </template>
      <template v-else>
        <div class="mobiScreen">
            <div v-for="(tourn, index) in nextTournament" :key="index">
                <b-row class="image">
                    <b-col v-if="tourn.course_image_file_1 !== ''" class="imagewidth" v-bind:style="{ 'background-image': 'url(' + 'https://ocs-asia.sgp1.cdn.digitaloceanspaces.com/apga/courses/' + season + '/' + tourn.course_code +'/' + 'File_1.jpg' + ')' }">
                    </b-col>
                    <b-col v-else class="imagewidth" v-bind:style="{ 'background-image': 'url(' + 'https://wp-alpstour.ocs-sport.com/wp-content/themes/alps-ocs/img/course.png' }">
                    </b-col>
                </b-row>  
                <div class="gradHome" :class="{adtgradHome : develop == 'adt'}"></div>
                <div class="logo">
                    <div class="outerLogo">
                        <template v-if="isMobile(true)">
                            <div class="innerLogoMob showMob">
                                <template v-if="tourn.sponsor_logo !== ''">
                                    <img class="logoImageMob" :src="tourn.sponsor_logo">
                                </template>
                                <template v-else>
                                    <img class="logoImageMob" src="https://asian.ocs-asia.com/misc/tourlogo.gif">
                                </template>
                            </div>
                        </template>
                        <template>
                            <div class="innerLogo showDesk">
                                <template v-if="tourn.sponsor_logo !== ''">
                                    <img class="logoImage" :src="tourn.sponsor_logo">
                                </template>
                                <template v-else>
                                    <img class="logoImageDefault" src="https://asian.ocs-asia.com/misc/tourlogo.gif">
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
                <b-row class="nextbtnColor" :class="{adtnextbtnColor : develop == 'adt'}">
                    <b-col>
                        <p class="dicDates">{{tourn.dic_dates}}</p>
                        <p class="fullName">{{tourn.full_name}}</p>
                        <p class="course ">{{tourn.course}}, {{tourn.course_country}}</p>
                        <!-- <p class="course"></p> -->
                        <p class="course">{{tourn.total_prize_fund}}</p>
                    </b-col>
                </b-row> 
                <template>
                  <b-row>
                    <b-col class="padRight">
                      <div class="FullListingBtn " :class="{adtFullListingBtn : develop == 'adt'}">
                      <!-- <div class="FullListingBtn"> -->
                        <b-button
                          :to="{
                            name: 'reports',
                            query: {
                              url: 'https://asian.ocs-asia.com/tic/tmentry.cgi?tourn=' + courseCode + '~season=' + Year + '~alphaorder~',
                              id: Year,
                              code: courseCode,
                              title: 'Tournament Entries'
                            }
                          }"
                        >
                          Entries
                        </b-button>
                      </div>
                    </b-col>
                    <b-col class="padLeft" v-if="Array.isArray(reports)">
                      <div class="FullListingBtn" :class="{adtFullListingBtn : develop == 'adt'}">
                        <b-button
                          :to="{
                            name: 'reports',
                            query: {
                              url: 'http://asian.ocs-asia.com/tic/tmdraw.cgi?tourn=' + courseCode+ '~season=' + Year + '~round=' + '1' + '~',
                              id: Year,
                              code: courseCode,
                              title: 'Round ' + '1' + ' Draw'
                            }
                          }"
                        >
                          <template>
                            Draw
                          </template>
                        </b-button>
                      </div>
                    </b-col>
                    <b-col v-else class="padLeft">
                      <div class="FullListingBtn" :class="{adtFullListingBtn : develop == 'adt'}">
                          <b-link @click="toggle" :to="'/tournament/' + course + '/' + season" class="btn btn-secondary" target="_self">View Tournament</b-link>
                      </div>
                    </b-col>
                  </b-row>
                </template>
            </div>
        </div>
      </template>
    </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Next Tourn",
  props: [
    "season",
    "data",
    "Newreport",
    "Year",
    "courseCode",
    "currentReport", 
    "title",
    "reportURL",
    "config",
    "multi_tourns",
    "pastT",
    "head",
    "tm",
    "past_tourns",
    "course",
    "live_scoring",
    "match",
    "Stableford",
    "reports",
    "nextRound",
    "roundBtns",
    "home",
    "develop"
  ],
  components: {
  },
  data() {
    return {
      tm_params: [],
      nextTournament: [],
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    isMobile() {
      if( screen.width <= 768 ) {
        return true;
      }
      else {
        return false;
      }
    },
  },
  mounted() {
    setTimeout(() => {
    axios //add API Call
      .get(
        process.env.VUE_APP_TIC_BASE +
          this.develop +
          "/" +
          this.season +
          "/tmticx?randomadd=" +
          new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then(response => {
        this.tm_params = response.data.tournaments.tournaments_entry
        this.code = response.data.code
        let arr = []
        var t_add=0
        this.nextTournament = arr
        // console.log("this.nextTournament")
        // console.log(this.nextTournament)
        if (this.reports == '') {
            this.tm_params.forEach((value, key) => {
                if (value.code == this.code) {
                        t_add = 0;
                        for (var i = 0; i <= t_add; i++) {
                            arr.push(response.data.tournaments.tournaments_entry[key + i]);
                            // console.log("this.nextTournament --")
                            // console.log(arr)
                        }
                }
            });
        } else {
            this.tm_params.forEach((value, key) => {
                if (value.code == this.code) {
                    if (response.data.ts_final_result=='Y') {
                        t_add = 1;
                        for (var i = 1; i <= t_add; i++) {
                            arr.push(response.data.tournaments.tournaments_entry[key + i]);
                            // console.log("this.nextTournament --")
                            // console.log(arr)
                        }
                    } else {
                        t_add = 0;
                        for (var w = 0; w <= t_add; w++) {
                            arr.push(response.data.tournaments.tournaments_entry[key + w]);
                            // console.log("this.nextTournament --")
                            // console.log(arr)
                        }
                    }
                }
            });   
        }
      })
    }, 1000)
  },
};
</script>

<style scoped>
/* Home page Css */
.adtnextbtnColor{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 250px; */
    height: 328px;
    margin-left: 0;
    margin-right: 0px;
    padding: 20px;
    padding-top: 80px;
    background-color: #76c3bd!important;
    background-image: linear-gradient(to right, #1c857c, #1c857c)!important;
}
p.course.courseCountry {
    margin-bottom: 0;
}
.liveScoring > a.btn {
  background-color: #aa0003d9!important;
  background-image: none!important;
}
::v-deep .FullListingBtn.adtFullListingBtn > a.btn {
  background-color: #76c3bd!important;
  background-image: linear-gradient(to right, #a5a5a5, #1c857c)!important;
  border-radius: 0;
  color: #fff !important;
  width: 100%;
  height: 59px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 3.1;
  background-repeat: no-repeat;
  border-color: #000000;
}
::v-deep .FullListingBtn.adtFullListingBtn > a.btn:hover {
  background-color: #1c857c!important;
  background-image: linear-gradient(to right, #1c857c, #1c857c)!important;
  border-radius: 0;
  color: #fff !important;
  width: 100%;
  height: 59px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 3.1;
  background-repeat: no-repeat;
  border-color: #000000;
}
::v-deep .FullListingBtn > a.btn{
    background-color: #D2682E;
    background-image: linear-gradient(#ffb21c, #D2682E);
    border-radius: 0;
    color: #fff !important;
    width: 100%;
    height: 59px;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 3.1;
    background-repeat: no-repeat;
    border-color: #ffb21c;
}
::v-deep .padRight {
    border-right: 1px solid #fff;
}
.FullListingBtn > a.btn:hover {
    background-color: #41a2f6!important;
    background-image: none;
    border-radius: 0;
    color: #fff !important;
    width: 100%;
    height: 59px;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 3.1;
    border-color: transparent;
}
.row.nextbtnColor.bkHome > div > .homeBlockInner > p.fullName {
    color: #fff;
    font-size: 15pt;
}
.row.nextbtnColor.bkHome > div > .homeBlockInner > p.dicDates {
    color: #fff;
    font-size: 12pt;
}
.padRight {
    padding-right: 0; 
}
.padLeft {
    padding-left: 0;
}
/* a.btn {
  background-color: #AA0003!important;
} */
.pulse {
  animation: pulse-red 2s infinite;
  border-left: 0px solid !important;
}
.pulse:hover {
  background-color: #ff5c39 !important;
}
@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(170, 0, 3, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
.pulsate {
  animation: pulsate 2s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
}
@keyframes pulsate {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}


.gradHome {
    background-color: #D2682E;
    background-image: linear-gradient(to right, #ffb21c, #D2682E);
    height: 15px;
}
.adtgradHome {
    background-color: #76c3bd!important;
    background-image: linear-gradient(to right, #76c3bd, #1c857c)!important;
    height: 15px;
}
.fullName {
    color: #0A3F7F;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    /* padding-top: 15px; */
}
.course {
    font-size: 16px !important;
    color: #fff;
    font-weight: 300;
}
.image {
    margin-left: 0;
    margin-right: 0px;
}
.imagewidth {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 150px;
}
.nextbtnColor {
    background-color: #ffffff;
    margin-left: 0;
    margin-right: 0px;
    /* border: 1px solid #e3e0e0; */
}
@media only screen and (max-width: 1200px) {
    .nextbtnColor {
        background-image: url(https://assets.asiantour.com/asian-tour/2024/09/Blue_Background.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: auto;
        margin-left: 0;
        margin-right: 0px;
        padding: 20px;
        padding-top: 30px;
    }
    .logoImageMob {
        width: 75px;
        text-align: center;
        margin: auto;
        display: block;
        position: relative;
        top: 20px;
    }
    .innerLogoMob {
        background-color: #fff;
        height: 120px;
        width: 120px;
        position: absolute;
        border-radius: 0;
        top: 13.3em;
        left: 155px;
        right: 70px;
    }
}
@media only screen and (max-width: 990px) {
  .homeBlockInner {
      padding-top: 50px;
      padding-bottom: 70px;
  }
}
@media only screen and (min-width: 769px) {
    .showMob {
      display: none;
    }
    .showDesk {
      display: block;
    }
}
@media only screen and (max-width: 768px) {
    .mobileTourn {
        margin-bottom: 30px;
    }
    .showMob {
      display: block;
    }
    .showDesk {
      display: none;
    }
}
@media only screen and (max-width: 600px) {

}
@media only screen and (max-width: 375px) {
}
.innerLogo {
    background-color: #fff;
    height: 110px;
    width: 145px;
    position: absolute;
    /* border-radius: 105px; */
    /* top: 10.3em; */
    top: 7.1em;
    left: 0;
    right: 0;
    margin: auto;
}
img.logoImage {
    width: 90px;
    text-align: center;
    margin: auto;
    display: block;
    position: relative;
    /* top: 7px; */
    top: 34px;
}
img.logoImageDefault {
    width: 95px;
    text-align: center;
    margin: auto;
    display: block;
    position: relative;
    top: 43px;
}
.dicDates {
    color: #fff;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 13px;
}
.gradHome {
    background-color: #D2682E;
    background-image: linear-gradient(to right, #ffb21c, #D2682E);
    height: 15px;
}
.fullName {
    font-size: 22px !important;
    color: #fff;
    margin-bottom: 13px;
}
.course {
    font-size: 16px !important;
    color: #fff;
    font-weight: 300;
}
.image {
    margin-left: 0;
    margin-right: 0px;
}
.imagewidth {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 250px; */
    height: 180px;
}
.nextbtnColor {
    background-image: url(https://assets.asiantour.com/asian-tour/2024/09/Blue_Background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 250px; */
    height: 328px;
    margin-left: 0;
    margin-right: 0px;
    padding: 20px;
    padding-top: 80px;
}
.FullListingBtn > a.btn {
    background-color: #042F62;
    border-radius: 0;
    color: #fff !important;
    width: 100%;
    height: 59px;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 3.1;
    border-color: #ffb21c;
}
.FullListingBtn > a.btn:hover {
    background-color: #5897E6;
    border-radius: 0;
    color: #fff !important;
    width: 100%;
    height: 59px;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 3.1;
    border-color: #ffb21c;
}
@media only screen and (max-width: 1200px) {
    .nextbtnColor {
        background-color: #093e7c;
        /* height: auto; */
        height: 330px;
        margin-left: 0;
        margin-right: 0px;
        padding: 20px;
        padding-top: 70px;
    }
    .logoImageMob {
        width: 75px;
        text-align: center;
        margin: auto;
        display: block;
        position: relative;
        top: 40px;
    }
    .innerLogoMob {
        background-color: #fff;
        height: 120px;
        width: 120px;
        position: absolute;
        border-radius: 105px;
        top: 13.3em;
        left: 155px;
        right: 70px;
    }
    .innerLogo {
      background-color: #fff;
      height: 110px;
      width: 145px;
      position: absolute;
      /* border-radius: 105px; */
      top: 6.3em;
      left: 0;
      right: 0;
      margin: auto;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 769px){
  .innerLogoMob {
    top: 6.3em;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0;
  }
}

@media only screen and (max-width: 768px) {
  .mobileTourn {
    margin-bottom: 30px;
  }
  .mobiScreen {
    margin-bottom: 30px;
  }
  .innerLogoMob {
    /* top: 10.3em; */
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0;
  }
}
@media only screen and (max-width: 768px) and (min-width: 500px){
  .innerLogoMob {
    top: 6.3em;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0;
  }
}
@media only screen and (max-width: 500px) {
  .innerLogoMob {
    top: 8.9em;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0;
  }
}
@media only screen and (max-width: 425px) {
}


/* Nav menu css */
.imagewidthNav {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
}
.nextbtnColorNav{
    background-color: #fff;
    margin-left: 0;
    margin-right: 0;
    border: 1px solid #e3e0e0;
}
.fullNameNav{
    color: #0a3f7f;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    padding-top: 15px;
}
.dicDatesNav{
    color: #6d6c6c;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 13px;
}

.FullListingBtnNav>a.btn {
    border-radius: 0;
    color: #fff !important;
    width: 100%;
    height: 43px;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 2.1;
    border-color: transparent;
    background-color: #D2682E;
    background-image: linear-gradient(#ffb21c, #D2682E);
}
.FullListingBtnNav>a.btn:hover {
    border-radius: 0;
    color: #fff !important;
    width: 100%;
    height: 43px;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 2.1;
    border-color: transparent;
    background-color: #41a2f6;
}
</style>
